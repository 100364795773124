import axios from 'axios';
import queryString from 'query-string';

const server_url = process.env.REACT_APP_SERVER_URL;

const getUserByToken = async (token) => {
  return await axios
    .get(`${server_url}/api/user/getUserByFirebaseID`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const listUser = async (token, params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/user/admin-distributor/getUserList?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const countListUser = async (token, params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/user/admin-distributor/count/getUserList?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const createUser = async (token, newUser) => {
  return await axios
    .post(`${server_url}/api/user/admin-distributor/createUser`, newUser, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

const getUserByID = async (token, params) => {
  const query = queryString.stringify(params)

  return await axios
    .get(`${server_url}/api/user/admin-distributor/getUserByID?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });
}

const updateUser = async (token, user) => {
  return await axios
    .post(`${server_url}/api/user/admin-distributor/updateUser`, user, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const changePassword = async (token, user) => {
  return await axios
    .post(`${server_url}/api/user/admin-distributor/changePassword`, user, {
      headers: { authorization: `Bearer ${token}` }
    })
    // .then(function (response) {
    //   return response;
    // })
    // .catch(function (error) {
    //   return error.response;
    // });
};

const changePasswordSystemGenerated = async (token, user) => {
  return await axios
    .post(`${server_url}/api/user/admin-distributor/changePasswordSystemGenerated`, user, {
      headers: { authorization: `Bearer ${token}` }
    })
    // .then(function (response) {
    //   return response;
    // })
    // .catch(function (error) {
    //   return error.response;
    // });
}

const deleteUser = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/user/admin-distributor/deleteUser`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

//ADMIN KC
const adminKCGetUserList = async (token, params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/user/adminKC/getUserList?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const adminKCCountGetUserList = async (token, params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/user/adminKC/count/getUserList?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const adminKCSearchUserList = async (token, params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/user/adminKC/search/getUserList?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const adminKCSearchCountUserList = async (token, params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/user/adminKC/search/count/getUserList?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

//Admin distributor

const getDistributorUserList = async (token, params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/user/admin-distributor/getUserList?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const countGetDistributorUserList = async (token, params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/user/admin-distributor/count/getUserList?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const searchAllDistributorUserList = async (token, params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/user/admin-distributor/search/getUserList?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const countSearchAllDistributorUserList = async (token, params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/user/admin-distributor/search/count/getUserList?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const filterDistributorUserList = async (token, params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/user/admin-distributor/filter/getUserList?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const countFilterDistributorUserList = async (token, params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/user/admin-distributor/filter/count/getUserList?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const updateUserEmailNotificationSetting = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/user/adminKC/updateUserEmailNotificationSetting`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

const updateUserFirstTimeLogin = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/user/distributor/updateUserFirstTimeLogin`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

export {
  getUserByToken,
  listUser,
  countListUser,
  createUser,
  getUserByID,
  updateUser,
  changePassword,
  changePasswordSystemGenerated,
  deleteUser,
  adminKCGetUserList,
  adminKCCountGetUserList,
  adminKCSearchUserList,
  adminKCSearchCountUserList,
  searchAllDistributorUserList,
  countSearchAllDistributorUserList,
  getDistributorUserList,
  countGetDistributorUserList,
  filterDistributorUserList,
  countFilterDistributorUserList,
  updateUserEmailNotificationSetting,
  updateUserFirstTimeLogin
}